package pl.com.codevibe.models

import pl.com.codevibe.utils.Constants.LOREM_IPSUM_LONG
import pl.com.codevibe.utils.Res

enum class Experience(
    val jobPosition: String,
    val description: String,
    val company: String,
    val companyWebsiteUrl: String,
    val companyLogo: String,
    val from: String,
    val to: String
) {
    Futuremind(
        jobPosition = "Android Developer",
        description = LOREM_IPSUM_LONG,
        company = "Futuremind",
        companyWebsiteUrl = "https://www.futuremind.com/",
        companyLogo = Res.Image.futuremind_logo,
        from = "May 2021",
        to = "NOW"
    ),
    Gigaset(
        jobPosition = "Android Developer",
        description = LOREM_IPSUM_LONG,
        company = "Gigaset Polska",
        companyWebsiteUrl = "https://www.gigaset.com/pl_pl/",
        companyLogo = Res.Image.gigaset_logo,
        from = "February 2020",
        to = "May 2021"
    ),
    Potional(
        jobPosition = "Android Developer",
        description = LOREM_IPSUM_LONG,
        company = "Potional",
        companyWebsiteUrl = "https://www.facebook.com/potional/?locale=pl_PL",
        companyLogo = Res.Image.potional_logo,
        from = "October 2018",
        to = "December 2019"
    ),
}