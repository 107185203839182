package pl.com.codevibe.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.TransitionTimingFunction
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import pl.com.codevibe.components.AppInfo
import pl.com.codevibe.components.BackToTopButton
import pl.com.codevibe.components.OverflowMenu
import pl.com.codevibe.models.Portfolio
import pl.com.codevibe.models.Theme
import pl.com.codevibe.sections.*

private const val ANIMATION_DURATION_MS = 350L

@Page("shoply/privacy-policy")
@Composable
fun ShoplyPrivacyPolicyPage() {
    Box(modifier = Modifier.fillMaxSize().backgroundColor(Theme.Black.rgb)) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(top = 150.px),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            with(Portfolio.Shoply) {
                AppInfo(
                    appName = title,
                    appIcon = appIcon,
                    appOwner = appOwner,
                    appDescription = description,
                )
            }
        }
    }
}
