package pl.com.codevibe.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import pl.com.codevibe.models.Theme
import pl.com.codevibe.utils.Constants

@Composable
fun AppName(
    appTitle: String,
    appIcon: String,
    appOwner: String,
    onClick: (() -> Unit)? = null,
) {
    Row {
        Image(
            src = appIcon,
            modifier = Modifier
                .size(150.px)
                .borderRadius(35.px)
                .onClick { onClick?.invoke() },
            desc = "$appTitle icon"
        )

        Column(
            modifier = Modifier
                .margin(left = 30.px)
        ) {
            P(
                attrs = Modifier
                    .fillMaxWidth()
                    .onClick { onClick?.invoke() }
                    .textAlign(TextAlign.Start)
                    .margin(
                        left = 0.px,
                        right = 0.px,
                        bottom = 10.px,
                        top = 0.px
                    )
                    .fontFamily(Constants.FONT_FAMILY)
                    .fontSize(40.px)
                    .fontWeight(FontWeight.Bold)
                    .color(Theme.LightBlue.rgb)
                    .toAttrs()
            ) {
                Text(appTitle)
            }
            PillText(appOwner)
        }
    }
}
