package pl.com.codevibe.styles

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderColor
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.focus
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px
import pl.com.codevibe.models.Theme

val InputStyle by ComponentStyle {
    base {
        Modifier
            .borderColor(Theme.Primary.rgb)
            .boxShadow(
                offsetX = 0.px,
                offsetY = 0.px,
                blurRadius = 0.px,
                spreadRadius = 0.px,
                color = null
            )
            .transition(
                CSSTransition(property = "border", duration = 200.ms),
                CSSTransition(property = "boxShadow", duration = 200.ms),
            )
    }
    hover {
        Modifier
            .borderColor(Theme.Blue.rgb)
            .boxShadow(
                offsetX = 0.px,
                offsetY = 0.px,
                blurRadius = 0.px,
                spreadRadius = 0.px,
                color = null
            )
    }
    focus {
        Modifier
            .borderColor(Theme.LightBlue.rgb)
            .boxShadow(
                offsetX = 0.px,
                offsetY = 0.px,
                blurRadius = 3.px,
                spreadRadius = 2.px,
                color = Theme.Primary.rgb
            )
            .transition(
                CSSTransition(property = "boxShadow", duration = 200.ms),
            )
    }
}