package pl.com.codevibe.styles

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.filter
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent

@OptIn(ExperimentalComposeWebApi::class)
val CompanyLogoImageBoxStyle by ComponentStyle {
    base {
        Modifier
            .styleModifier {
                filter { opacity(65.percent) }
            }
            .transition(CSSTransition(property = "filter", duration = 300.ms))
    }
    hover {
        Modifier
            .styleModifier {
                filter { opacity(100.percent) }
            }
    }
}