package pl.com.codevibe.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.browser.window
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import pl.com.codevibe.components.Header
import pl.com.codevibe.components.SocialBar
import pl.com.codevibe.models.Section
import pl.com.codevibe.models.Theme
import pl.com.codevibe.styles.MainButtonStyle
import pl.com.codevibe.styles.MainImageBoxStyle
import pl.com.codevibe.styles.MainImageStyle
import pl.com.codevibe.utils.Constants.FONT_FAMILY
import pl.com.codevibe.utils.Res

@Composable
fun MainSection(onMenuClicked: () -> Unit) {

    LaunchedEffect(Unit) {
        window.setTimeout({
            initializeParticles()
        }, 500)
    }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .id(Section.Home.id),
        contentAlignment = Alignment.TopCenter
    ) {
        MainBackground()
        Box(modifier = Modifier.fillMaxSize().id("particlesBox").pointerEvents(PointerEvents.Inherit))
        MainContent(onMenuClicked = onMenuClicked)
    }
}

@Composable
fun MainBackground() {
    Box(
        modifier = Modifier
            .fillMaxSize()
            .backgroundColor(Theme.Black.rgb)
    )
    Image(
        modifier = Modifier
            .fillMaxSize()
            .objectFit(ObjectFit.Cover),
        src = Res.Image.background,
        desc = "Background Image"
    )
}

fun initializeParticles() {
    window.run {
        js("particlesJS.load('particlesBox', 'particlesjs-config.json')")
    }
}

@Composable
fun MainContent(onMenuClicked: () -> Unit) {
    val breakpoint = rememberBreakpoint()

    var mainTextMargin by remember { mutableStateOf((-100).percent) }
    var mainImageMargin by remember { mutableStateOf(100.percent) }

    LaunchedEffect(Unit) {
        delay(600)
        mainTextMargin = 0.percent
        mainImageMargin = 0.percent
    }

    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.SpaceBetween,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Header(onMenuClicked = onMenuClicked)
        Column(
            modifier = Modifier.fillMaxSize(),
            verticalArrangement = Arrangement.Bottom,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            SimpleGrid(
                modifier = Modifier.fillMaxWidth(
                    if (breakpoint >= Breakpoint.MD) 80.percent else 90.percent
                ),
                numColumns = numColumns(base = 1, md = 2)
            ) {
                MainText(breakpoint = breakpoint, modifier = Modifier.translateX(mainTextMargin).transition(CSSTransition(property = "translate", duration = 800.ms)))
                MainImage(breakpoint = breakpoint, modifier = Modifier.translateX(mainImageMargin).transition(CSSTransition(property = "translate", duration = 800.ms)))
            }
        }
    }
}

@Composable
fun MainText(
    breakpoint: Breakpoint,
    modifier: Modifier = Modifier
) {
    Row(
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
    ) {
        if (breakpoint > Breakpoint.MD) {
            SocialBar()
        }
        Column {
            P(
                attrs = Modifier
                    .margin(topBottom = 0.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(if (breakpoint >= Breakpoint.LG) 45.px else 20.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.Primary.rgb)
                    .toAttrs()
            ) {
                Text("Hello, I'm")
            }
            P(
                attrs = Modifier
                    .margin(top = 20.px, bottom = 0.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(if (breakpoint >= Breakpoint.LG) 68.px else 40.px)
                    .fontWeight(FontWeight.Bolder)
                    .color(Theme.LightBlue.rgb)
                    .toAttrs()
            ) {
                Text("Mateusz Druć")
            }
            P(
                attrs = Modifier
                    .margin(top = 10.px, bottom = 5.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(20.px)
                    .fontWeight(FontWeight.Bold)
                    .color(Theme.LightBlue.rgb)
                    .toAttrs()
            ) {
                Text("Native Android Developer")
            }
            P(
                attrs = Modifier
                    .margin(bottom = 25.px)
                    .maxWidth(400.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(15.px)
                    .fontStyle(FontStyle.Italic)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.Blue.rgb)
                    .toAttrs()
            ) {
                Text(Res.Text.codevibe_short_description)
            }
            Button(
                attrs = MainButtonStyle.toModifier()
                    .height(40.px)
                    .border(width = 0.px)
                    .borderRadius(r = 5.px)
                    .backgroundColor(Theme.Primary.rgb)
                    .color(Colors.White)
                    .cursor(Cursor.Pointer)
                    .margin(bottom = 25.px)
                    .toAttrs()
            ) {
                Link(
                    modifier = Modifier
                        .color(Colors.White)
                        .textDecorationLine(TextDecorationLine.None),
                    text = "Hire me",
                    path = Section.Contact.path
                )
            }
        }
    }
}

@Composable
fun MainImage(
    breakpoint: Breakpoint,
    modifier: Modifier = Modifier
) {
    Column(
        modifier = modifier
            .fillMaxSize(80.percent)
            .fillMaxHeight(),
        verticalArrangement = Arrangement.Bottom
    ) {
        Box(modifier = MainImageBoxStyle.toModifier()
            .fillMaxSize()
            .clip(Circle())
            .margin(top = 30.px, bottom = 50.px, left = 20.px, right = 20.px)
        ) {
            Image(
                modifier = MainImageStyle.toModifier()
                    .fillMaxWidth()
                    .border(width = 5.px, style = LineStyle.Solid, color = Theme.DarkBlue.rgb)
                    .borderRadius(50.percent)
                    .clip(Circle()),
                src = Res.Image.main,
                desc = "Main Image"
            )
            P(
                attrs = Modifier
                    .id("imageText")
                    .margin(bottom = 25.px)
                    .fontFamily(FONT_FAMILY)
                    .opacity(70.percent)
                    .fontSize(if (breakpoint >= Breakpoint.LG) 16.px else 14.px)
                    .align(Alignment.BottomCenter)
                    .alignContent(AlignContent.Center)
                    .zIndex(1)
                    .toAttrs()
            ) {
                Text("Avatar generated with AI")
            }
        }
    }
}