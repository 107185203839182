package pl.com.codevibe.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import pl.com.codevibe.models.Theme
import pl.com.codevibe.styles.PortfolioAppSmallIconStyle

@Composable
fun SmallAppIcon(
    appIcon: String,
    isSelected: Boolean = false,
    onClick: () -> Unit
) {
    val borderStyle = if (isSelected) LineStyle.Solid else LineStyle.Dotted
    val borderColor = if (isSelected) Theme.LightBlue.rgb else Theme.Blue.rgb
    val alpha = if (isSelected) 100.percent else 80.percent
//    val borderWidth = if (isSelected)

    Image(
        src = appIcon,
        modifier = PortfolioAppSmallIconStyle.toModifier()
            .size(60.px)
            .borderRadius(50.percent)
            .borderStyle(borderStyle)
            .borderColor(borderColor)
            .margin(leftRight = 8.px)
            .opacity(alpha)
            .padding(2.px)
            .cursor(Cursor.Pointer)
            .onClick { onClick.invoke() },
        desc = "App small icon"
    )
}