package pl.com.codevibe.components

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import org.jetbrains.compose.web.css.px
import pl.com.codevibe.models.Portfolio

@Composable
fun PortfolioAppSelector(
    modifier: Modifier = Modifier,
    onAppSelected: (Portfolio) -> Unit
) {
    var selectedApp by remember {
        mutableStateOf<Portfolio>(Portfolio.Shoply)
    }

    Row(
        modifier = modifier
            .margin(bottom = 50.px)
            .padding(topBottom = 10.px)
            .overflow(Overflow.Hidden)
            .scrollBehavior(ScrollBehavior.Smooth)
    ) {
        Portfolio.values().forEach { application ->
            SmallAppIcon(
                appIcon = application.appIcon,
                isSelected = selectedApp == application,
                onClick = {
                    selectedApp = application
                    onAppSelected.invoke(application)
                })
        }
    }
}