package pl.com.codevibe.models

import pl.com.codevibe.utils.Constants.LOREM_IPSUM_SHORTEST
import pl.com.codevibe.utils.Res

enum class Service(
    val icon: String,
    val imageDesc: String,
    val title: String,
    val description: String
) {
    Android(
        icon = Res.Icon.android,
        imageDesc = "Android Icon",
        title = "Advanced Android Development",
        description = "Creating modern, native mobile applications using the latest technologies and good programming practices."
    ),
    Web(
        icon = Res.Icon.web,
        imageDesc = "Desktop Icon",
        title = "Simple Web Development",
        description = "Creating simple, modern, responsive websites. Need a simple but modern website like this one? No problem, you've come to the right place!"
    ),
    Advisory(
        icon = Res.Icon.brain,
        imageDesc = "Brain Icon",
        title = "IT Advisory",
        description = "Are you thinking about creating an application? Do you have an idea, but don't know if it is good and feasible? I will analyze your problem and then try to dispel all your doubts and advise a solution."
    ),
    Technologies(
        icon = Res.Icon.technologies,
        imageDesc = "Blocks Icon",
        title = "Modern Technologies Usage",
        description = "I am constantly self-developing, completing my knowledge with the latest technologies and solutions. In my projects I use, among others: Kotlin, Jetpack Compose, screenshot testing, Kobweb and others."
    ),
    Transparency(
        icon = Res.Icon.diagram,
        imageDesc = "Diagram Icon",
        title = "Transparency",
        description = "With each settlement, you will receive a report containing a detailed breakdown of the work performed, along with the time spent on it. Good communication and being in constant contact with the client is my priority."
    ),
    Price(
        icon = Res.Icon.money,
        imageDesc = "Money Icon",
        title = "Good price",
        description = "I offer a reasonable, competitive price for the services provided. We will definitely get along!"
    )
}