package pl.com.codevibe.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.functions.opacity
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowLeft
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowRight
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import pl.com.codevibe.components.AppInfo
import pl.com.codevibe.components.AppScreenshotCard
import pl.com.codevibe.components.PortfolioAppSelector
import pl.com.codevibe.components.SectionTitle
import pl.com.codevibe.models.Portfolio
import pl.com.codevibe.models.Section
import pl.com.codevibe.models.Theme
import pl.com.codevibe.styles.PortfolioArrowIconStyle
import pl.com.codevibe.utils.animateNumber

private const val ANIMATION_DURATION_MS = 350L
private const val TRANSLATE_VALUE = 10

@Composable
fun PortfolioSection() {
    Box(
        modifier = Modifier
            .id(Section.Portfolio.id)
            .fillMaxWidth()
            .backgroundColor(Theme.Black.rgb)
            .padding(topBottom = 100.px),
//            .maxWidth(SECTION_WIDTH.px),
        contentAlignment = Alignment.Center
    ) {
        PortfoltioContent()
    }
}

@Composable
private fun PortfoltioContent() {
    val breakpoint = rememberBreakpoint()
    val ctx = rememberPageContext()
    var selectedApp by remember {
        mutableStateOf<Portfolio>(Portfolio.Shoply)
    }
    var appToBeSelected by remember {
        mutableStateOf<Portfolio>(Portfolio.Shoply)
    }
    var opacity by remember {
        mutableStateOf(100.percent)
    }
    var animatedTranslate by remember {
        mutableStateOf(0.percent)
    }

    LaunchedEffect(opacity) {
        if (opacity == 0.percent) {
            delay(ANIMATION_DURATION_MS)
            selectedApp = appToBeSelected
        }
    }

    LaunchedEffect(selectedApp) {
        animatedTranslate = 0.percent
        opacity = 100.percent
    }

    LaunchedEffect(appToBeSelected) {
        delay(1000)
        if (selectedApp != appToBeSelected || opacity == 0.percent) {
            selectedApp = appToBeSelected
            animatedTranslate = 0.percent
            opacity = 100.percent
        }
    }

    Column(
        modifier = Modifier
            .fillMaxWidth(if (breakpoint >= Breakpoint.MD) 100.percent else 90.percent),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        SectionTitle(
            modifier = Modifier.margin(bottom = 25.px),
            section = Section.Portfolio,
            alignment = Alignment.CenterHorizontally
        )
        PortfolioAppSelector { selectedApplication ->
            if (appToBeSelected != selectedApplication) {
                if (opacity == 100.percent) {
                    appToBeSelected = selectedApplication
                    animatedTranslate = TRANSLATE_VALUE.percent
                    opacity = 0.percent
                } else {
                    appToBeSelected = selectedApplication
                }
            }
        }

        SimpleGrid(
            modifier = Modifier.fillMaxWidth(
                if (breakpoint >= Breakpoint.MD) 60.percent else 90.percent
            ),
            numColumns = numColumns(base = 1, lg = 2)
        ) {
            with(selectedApp) {
                AppInfo(
                    appName = title,
                    appIcon = appIcon,
                    appOwner = appOwner,
                    appDescription = description,
                    onClick = { if (selectedApp == Portfolio.Shoply) ctx.router.navigateTo("/shoply") },
                    modifier = Modifier
                        .opacity(opacity)
                        .translateY(animatedTranslate)
                        .transition(
                            CSSTransition(property = "opacity", duration = ANIMATION_DURATION_MS.ms, timingFunction = TransitionTimingFunction.EaseInOut),
                            CSSTransition(property = "translate", duration = ANIMATION_DURATION_MS.ms, timingFunction = TransitionTimingFunction.EaseInOut)
                        )
                )
            }
            AppScreenshotCards(
                selectedApp,
                breakpoint,
                modifier = Modifier
                    .opacity(opacity)
                    .translateY(animatedTranslate)
                    .transition(
                        CSSTransition(property = "opacity", duration = ANIMATION_DURATION_MS.ms, timingFunction = TransitionTimingFunction.EaseInOut),
                        CSSTransition(property = "translate", duration = ANIMATION_DURATION_MS.ms, timingFunction = TransitionTimingFunction.EaseInOut)
                    )
            )
        }

    }
}

@Composable
fun AppScreenshotCards(portfolio: Portfolio, breakpoint: Breakpoint, modifier: Modifier = Modifier) {
    Column(
        modifier = modifier.fillMaxWidth().margin(left = if (breakpoint >= Breakpoint.MD) 40.px else 0.px),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Row(
            modifier = Modifier
                .id("scrollableContainer")
                .fillMaxWidth()
                .margin(25.px)
                .maxWidth(
                    if (breakpoint > Breakpoint.MD) 850.px
                    else if (breakpoint > Breakpoint.SM) 650.px
                    else 425.px
                )
                .overflow(Overflow.Hidden)
                .scrollBehavior(ScrollBehavior.Smooth)
        ) {
            portfolio.screenshots.forEach { screenshot ->
                AppScreenshotCard(
                    modifier = Modifier.margin(
                        right = if (screenshot != portfolio.screenshots.last()) 10.px else 0.px,
                        bottom = 20.px
                    ),
                    image = screenshot
                )
            }
        }
        AppScreenshotsNavigation()
    }
}

@Composable
private fun AppScreenshotsNavigation() {
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.Center
    ) {
        FaArrowLeft(
            modifier = PortfolioArrowIconStyle.toModifier()
                .margin(right = 40.px)
                .cursor(Cursor.Pointer)
                .onClick {
                    document.getElementById("scrollableContainer")
                        ?.scrollBy(x = -225.0, y = 0.0)
                },
            size = IconSize.LG
        )

        FaArrowRight(
            modifier = PortfolioArrowIconStyle.toModifier()
                .margin(right = 40.px)
                .cursor(Cursor.Pointer)
                .onClick {
                    document.getElementById("scrollableContainer")
                        ?.scrollBy(x = 225.0, y = 0.0)
                },
            size = IconSize.LG
        )
    }
}