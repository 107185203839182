package pl.com.codevibe.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import pl.com.codevibe.models.Theme
import pl.com.codevibe.utils.Constants

@Composable
fun PillText(text: String) {
    Box(
        modifier = Modifier
            .borderRadius(25.px)
            .backgroundColor(Theme.Primary.rgb)
    ) {
        P(
            attrs = Modifier
                .fillMaxWidth()
                .textAlign(TextAlign.Start)
                .margin(topBottom = 6.px, leftRight = 14.px)
                .fontFamily(Constants.FONT_FAMILY)
                .fontSize(12.px)
                .fontWeight(FontWeight.Normal)
                .color(Theme.LightBlue.rgb)
                .toAttrs()
        ) {
            Text(text)
        }
    }
}