package pl.com.codevibe.styles

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.anyLink
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import pl.com.codevibe.models.Theme

val NavigationItemStyle by ComponentStyle {
    base {
        Modifier
            .color(Theme.Blue.rgb)
            .transition(CSSTransition(property = "color", duration = 200.ms))
    }
    anyLink {
        Modifier
            .color(Theme.Blue.rgb)
    }
    hover {
        Modifier
            .color(Theme.LightBlue.rgb)
    }
}

@OptIn(ExperimentalComposeWebApi::class)
val LogoStyle by ComponentStyle {
    base {
        Modifier
            .scale(1.0)
            .transform { rotate(0.deg) }
            .transition(
                CSSTransition(property = "scale", duration = 200.ms),
                CSSTransition(property = "rotate", duration = 200.ms)
            )
    }
    hover {
        Modifier
            .scale(1.1)
            .transform { rotate((-5).deg) }
    }
}

val SocialLinkStyle by ComponentStyle {
    base {
        Modifier.color(Theme.Blue.rgb)
            .transition(CSSTransition(property = "color", duration = 200.ms))
    }
    hover {
        Modifier.color(Theme.Primary.rgb)
    }
}

val MainButtonStyle by ComponentStyle {
    base {
        Modifier
            .width(100.px)
            .transition(CSSTransition(property = "width", duration = 200.ms))
    }
    hover {
        Modifier.width(120.px)
    }
}

@OptIn(ExperimentalComposeWebApi::class)
val MainImageStyle by ComponentStyle {
    base {
        Modifier
            .styleModifier {
                filter { grayscale(70.percent) }
            }
            .transition(CSSTransition(property = "filter", duration = 300.ms))
    }
    hover {
        Modifier
            .styleModifier {
                filter { grayscale(0.percent) }
            }
    }
}

val MainImageBoxStyle by ComponentStyle {
    cssRule(" > #imageText") {
        Modifier.color(Colors.Transparent)
            .transition(
                CSSTransition(
                    property = "color",
                    duration = 700.ms,
                    timingFunction = AnimationTimingFunction.Ease,
                    delay = 200.ms
                )
            )
    }

    cssRule(":hover > #imageText") {
        Modifier.color(Theme.LightBlue.rgb)
    }
}