package pl.com.codevibe.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import pl.com.codevibe.components.BackToTopButton
import pl.com.codevibe.components.OverflowMenu
import pl.com.codevibe.models.Theme
import pl.com.codevibe.sections.*

@Page
@Composable
fun HomePage() {
    var menuOpened by remember { mutableStateOf(false) }

    var opacity by remember { mutableStateOf(0.percent) }

    LaunchedEffect(Unit) {
        delay(500)
        opacity = 100.percent
    }

    Box(modifier = Modifier.fillMaxSize().backgroundColor(Theme.Black.rgb)) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .opacity(opacity)
                .transition(CSSTransition(property = "opacity", duration = 1500.ms)),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            MainSection(onMenuClicked = { menuOpened = true })
            ServiceSection()
            PortfolioSection()
            ExperienceSection()
            ContactSection()
            FooterSection()
        }
        BackToTopButton()
        if (menuOpened) {
            OverflowMenu(onMenuClosed = { menuOpened = false })
        }
    }
}
