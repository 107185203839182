package pl.com.codevibe.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color.Companion.argb
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.px
import pl.com.codevibe.styles.PortfolioSectionStyle
import pl.com.codevibe.utils.Constants.WEBSITE
import pl.com.codevibe.utils.Res

@Composable
fun AppScreenshotCard(
    modifier: Modifier = Modifier,
    image: String,
    link: String = WEBSITE
) {
    Image(
        modifier = modifier
            .fillMaxSize()
            .maxWidth(200.px)
            .borderRadius(20.px)
            .objectFit(ObjectFit.Fill),
        src = image,
        desc = "Portfolio Image"
    )

//    Link(
//        modifier = PortfolioSectionStyle.toModifier().textDecorationLine(TextDecorationLine.None),
//        path = link,
//        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
//    ) {
////        Column(modifier = modifier.id("columnParent")) {
//        Box(
//            modifier = modifier
//                .id("boxParent")
//                .fillMaxWidth()
//                .maxWidth(200.px)
//                .borderRadius(20.px)
//                .margin(bottom = 20.px)
//        ) {
//            Image(
//                modifier = Modifier
//                    .fillMaxSize()
//                    .objectFit(ObjectFit.Fill),
//                src = image,
//                desc = "Portfolio Image"
//            )
//                Box(
//                    modifier = Modifier
//                        .id("greenOverlay")
//                        .fillMaxHeight()
//                        .backgroundColor(argb(a = 0.5f, r = 0, g = 167, b = 142)),
//                    contentAlignment = Alignment.Center
//                ) {
//                    Image(
//                        modifier = Modifier
//                            .id("linkIcon")
//                            .size(32.px),
//                        src = Res.Icon.link,
//                        desc = "Link Icon"
//                    )
//                }
//            }
//        }
//    }
}