package pl.com.codevibe.models

import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgb

enum class Theme(
    val hex: String,
    val rgb: CSSColorValue
) {
    Primary(hex = "#0F4C75", rgb = rgb(r = 15, g = 76, b = 117)),
    Secondary(hex = "#1B262C", rgb = rgb(r = 27, g = 38, b = 44)),
    Blue(hex = "#3282B8", rgb = rgb(r = 50, g = 130, b = 184)),
    LightBlue(hex = "#BBE1FA", rgb = rgb(r = 187, g = 225, b = 250)),
    DarkBlue(hex = "#040d12", rgb = rgb(r = 4, g = 13, b = 18)),
    Black(hex = "#121212", rgb = rgb(r = 18, g = 18, b = 18)),
}