package pl.com.codevibe.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import pl.com.codevibe.models.Theme
import pl.com.codevibe.utils.Constants

@Composable
fun AppInfo(
    appName: String,
    appIcon: String,
    appOwner: String,
    appDescription: String,
    modifier: Modifier = Modifier,
    onClick: (() -> Unit)? = null,
) {
    Column(modifier = modifier) {
        AppName(
            appTitle = appName,
            appIcon = appIcon,
            appOwner = appOwner,
            onClick = onClick,
        )
        P(
            attrs = Modifier
                .margin(left = 0.px, right = 30.px, top = 30.px, bottom = 30.px)
                .fontFamily(Constants.FONT_FAMILY)
                .fontSize(18.px)
                .lineHeight(1.6)
                .fontWeight(FontWeight.Normal)
                .color(Theme.Blue.rgb)
                .toAttrs()
        ) {
            Text(appDescription)
        }
    }
}