package pl.com.codevibe.models

import pl.com.codevibe.utils.Constants.LOREM_IPSUM_LONG
import pl.com.codevibe.utils.Res

enum class Portfolio(
    val appIcon: String,
    val screenshots: List<String>,
    val title: String,
    val description: String,
    val appOwner: String
) {
    Shoply(
        appIcon = Res.Image.shoply_app_logo,
        screenshots = listOf(
            Res.Image.shoply_1,
            Res.Image.shoply_2,
            Res.Image.shoply_3,
            Res.Image.shoply_4,
            Res.Image.shoply_5
        ),
        title = "Shoply",
        description = "Introducing Shoply, the ultimate shopping companion app designed to streamline your grocery trips with simplicity and efficiency. Easily create interactive shopping lists tailored to your needs, whether it's for a weekly grocery run or a special occasion. Share lists seamlessly with friends and family to divide and conquer your shopping tasks.\n\n\n\nShoply prioritizes user-friendly functionality without overwhelming extras, ensuring a hassle-free experience every time. Enjoy features like real-time list updates, intuitive item categorization for easy navigation, and automatic notifications to keep you on track. Say goodbye to forgotten items and hello to smoother, more collaborative shopping adventures with Shoply.",
        appOwner = "CODEVIBE"
    ),
    Zappka(
        appIcon = Res.Image.zappka_app_logo,
        screenshots = listOf(
            Res.Image.zappka_1,
            Res.Image.zappka_2,
            Res.Image.zappka_3,
            Res.Image.zappka_4,
            Res.Image.zappka_5,
            Res.Image.zappka_6,
            Res.Image.zappka_7,
            Res.Image.zappka_8
        ),
        title = "żappka",
        description = LOREM_IPSUM_LONG,
        appOwner = "ŻABKA POLSKA Sp. z o.o."
    ),
    GigasetElements(
        appIcon = Res.Image.gigaset_app_logo,
        screenshots = listOf(
            Res.Image.gigaset_1,
            Res.Image.gigaset_2,
            Res.Image.gigaset_3,
            Res.Image.gigaset_4,
            Res.Image.gigaset_5,
            Res.Image.gigaset_6,
            Res.Image.gigaset_7,
            Res.Image.gigaset_8,
        ),
        title = "Gigaset elements",
        description = LOREM_IPSUM_LONG,
        appOwner = "Gigaset Communications\nPolska Sp. z o.o."
    )
}